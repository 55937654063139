@use './index.scss' as config;

#onetrust-consent-sdk #onetrust-banner-sdk {
  background-color: config.getColour('grey80');

  & #onetrust-button-group button {
    color: config.getColour('cream') !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }

  & #onetrust-policy-text {
    color: config.getColour('cream') !important;
    font-weight: 300;
    font-family: 'europa', 'Arial Bold', sans-serif;
    font-size: 0.8rem !important;
    line-height: 1.75;
  }

  & * {
    color: config.getColour('cream');
    font-weight: 200;
  }

  & button {
    background-color: config.getColour('grey80');
    border-color: config.getColour('cream');
    border-radius: 40px;
  }
}

#onetrust-consent-sdk #onetrust-pc-sdk {
  & #accept-recommended-btn-handler,
  & .ot-pc-refuse-all-handler,
  & .onetrust-close-btn-handler {
    background-color: config.getColour('grey80') !important;
  }
}

.ot-floating-button__front.ot-floating-button__front.ot-floating-button__front {
  background-image: url(../images/cookie.png);
}

#ot-sdk-btn-floating .ot-floating-button__back {
  background-color: config.getColour('grey80') !important;

  & svg g {
    fill: config.getColour('cream') !important;
  }
}

#onetrust-banner-sdk {
  background-color: config.getColour('grey80') !important;
}

#ot-sdk-cookie-policy {
  margin-bottom: 50px;

  & #onetrust-button-group button {
    color: config.getColour('cream') !important;
  }

  & #cookie-policy-title {
    color: config.getColour('primary50') !important;
    font-size: 2.125rem;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
  }

  & #cookie-policy-description,
  & .ot-sdk-cookie-policy-group,
  & .ot-sdk-cookie-policy-group-desc,
  & .ot-host-td,
  & .ot-cookies-type-td-content {
    color: config.getColour('cream') !important;
    font-weight: 300;
    font-family: 'europa', 'Arial Bold', sans-serif;
    font-size: 1.125rem !important;
    line-height: 1.75;
  }

  & .ot-cookies-td-content > a {
    background-color: config.getColour('grey80') !important;
    color: config.getColour('cream') !important;
    padding: 3px;

    &:hover {
      color: config.getColour('grey80') !important;
      background-color: config.getColour('cream') !important;
    }
  }
}

#onetrust-close-btn-container button {
  background-image: url(../../public/ot_close.svg) !important;
}
