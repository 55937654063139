@use '@scss/index.scss' as config;

.swiper-thumbs.swiper-thumbs {
    padding: 50px 0;

    @include config.breakpoint('sm', 'down') {
        padding: 25px 0;
    }
}

.swiper-thumbs .swiper-wrapper {
    overflow: visible;
}

.swiper-thumbs .swiper-slide-active {
    overflow: visible;
    margin: 0 20px 0 10px !important;
}

.swiper-thumbs .swiper-slide-active > div {
    transform: scale(1.25);
    transform-origin: center center;
    z-index: 2;
    border: solid 2px config.getColour('primary50');
}
