@use '@scss/index.scss' as config;

@mixin baseButtonStyles() {
  border-radius: 48px;
  color: config.getColour('white');
  font-family: config.getTypo('h300', 'family');
  font-weight: config.getTypo('h300', 'weight');
  text-decoration: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  min-height: 2em;
  min-width: 60px;
}

.button {
  @include baseButtonStyles();
  margin-bottom: config.getSpacer('xxs');
}

.fill {
  width: 100%;
  text-align: center;
}

@mixin smallButtonStyles() {
  padding: 4px 12px;
  font-size: config.getTypo('h200', 'size');
}

.small {
  @include smallButtonStyles();
}

.medium {
  padding: 12px 20px;
  font-size: config.getTypo('h200', 'size');
}

.large {
  padding: 12px 24px;
  font-size: config.getTypo('h300', 'size');
}

@mixin primaryStyles() {
  background-color: config.getColour('complementary2');
  color: config.getColour('grey100');
  border: 1px solid config.getColour('complementary2');

  &:hover {
    background-color: config.getColour('white');
    color: config.getColour('grey100');
    border: 1px solid config.getColour('white');
  }
}

.primary {
 @include primaryStyles();
}

@mixin secondaryStyles() {
  background-color: transparent;
  color: config.getColour('grey0');
  border: 1px solid config.getColour('grey40');

  &:hover {
    background-color: config.getColour('grey40');
    color: config.getColour('grey100');
    border: 1px solid config.getColour('grey40');
  }
}

.secondary {
  @include secondaryStyles();
}

@mixin tertiaryStyles() {
  background-color: config.getColour('grey0');
  color: config.getColour('grey100');
  border: 1px solid config.getColour('grey0');

  &:hover {
    background-color: config.getColour('grey40');
    color: config.getColour('grey100');
    border: 1px solid config.getColour('grey40');
  }
}

.tertiary {
  @include tertiaryStyles();
}

.danger-primary {
  background-color: config.getColour('alert50');
  color: config.getColour('white');

  &:hover {
    background-color: config.getColour('alert100');
  }
}

.danger-secondary {
  background-color: transparent;
  color: config.getColour('alert50');
  border: 1px solid config.getColour('alert50');

  &:hover {
    background-color: config.getColour('alert05');
  }
}

.success-primary {
  background-color: config.getColour('success50');
  color: config.getColour('white');

  &:hover {
    background-color: config.getColour('success100');
  }
}

.success-secondary {
  background-color: transparent;
  color: config.getColour('success50');
  border: 1px solid config.getColour('success50');

  &:hover {
    background-color: config.getColour('success05');
  }
}

.disabled {
  background-color: transparent;
  color: rgba(config.getColour('white'), 0.3);
  border: 1px solid rgba(config.getColour('white'), 0.3);

  &:hover {
    background-color: transparent;
    color: rgba(config.getColour('white'), 0.3);
    border: 1px solid rgba(config.getColour('white'), 0.3);
    cursor: not-allowed !important;
  }
}
