@use '@scss/index.scss' as config;

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #000000;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: config.getColour('white');
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: config.getColour('grey0');
  --toastify-icon-color-error: white;

  --toastify-toast-width: 460px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  // Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: white;
}

.Toastify__toast.Toastify__toast {
  @include config.getHeader('h5');
  border-radius: 15px;
}

.Toastify__toast--error {
  background-color: config.getColour('alert50');
  color: white;

  & .Toastify__close-button--light {
    opacity: 1;
    align-self: center;
  }

  & .Toastify__close-button--light svg path {
    fill: config.getColour('grey0');
  }
}

.Toastify__toast--success {
  background-color: config.getColour('success100');
  color: white;
}

.Toastify__toast--info.Toastify__toast-theme--dark {
  background-color: config.getColour('grey100');
  color: white;
  border: 1px solid config.getColour('grey80');

  & .Toastify__toast-icon svg path {
    fill: config.getColour('grey0');
  }
}

.Toastify__toast-icon {
  align-self: center;
}

.Toastify__toast-container.Toastify__toast-container {
  width: 450px;

  @include config.breakpoint('sm', 'down') {
    width: 350px;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
}
