@use 'sass:map';
@use 'sass:math';

@use './colours.scss' as colour;

$primary-font-family: 'europa', Arial, sans-serif;
$primary-font-weight: 700;

$secondary-font-family: 'manrope';
$secondary-font-weight: 400;

$root-px: 16px;

@function pxToRem($pxValue) {
  @return math.div($pxValue, $root-px) * 1rem;
}

$primary-sizes: (
  'h900': (
    'size': pxToRem(72px),
    'line-height': 2.5em
  ),
  'h800': (
    'size': pxToRem(56px),
    'line-height': 2.5em
  ),
  'h700': (
    'size': pxToRem(40px),
    'line-height': 2.5em
  ),
  'h600': (
    'size': pxToRem(32px),
    'line-height': 2.5em
  ),
  'h500': (
    'size': pxToRem(24px),
    'line-height': 2.5em
  ),
  'h400': (
    'size': pxToRem(20px),
    'line-height': 1.5em
  ),
  'h300': (
    'size': pxToRem(16px),
    'line-height': 1.5em
  ),
  'h200': (
    'size': pxToRem(14px),
    'line-height': 1.5em
  ),
  'h100': (
    'size': pxToRem(12px),
    'line-height': 1.5em
  )
);

$primary-set: ();

@each $key, $value in $primary-sizes {
  $primary-set: map.deep-merge(
    $primary-set,
    map.set($primary-sizes, $key, 'weight', $primary-font-weight)
  );

  $primary-set: map.deep-merge(
    $primary-set,
    map.set($primary-sizes, $key, 'family', $primary-font-family)
  );
}

$secondary-sizes: (
  'subtitle': (
    'size': pxToRem(20px),
    'line-height': 1.5em
  ),
  'bodyLarge': (
    'size': pxToRem(16px),
    'line-height': 2em
  ),
  'bodyMedium': (
    'size': pxToRem(14px),
    'line-height': 2em
  ),
  'bodySmall': (
    'size': pxToRem(12px),
    'line-height': 2em
  )
);

$secondary-set: ();

@each $key, $value in $secondary-sizes {
  $secondary-set: map.deep-merge(
    $secondary-set,
    map.set($secondary-sizes, $key, 'weight', $secondary-font-weight)
  );

  $secondary-set: map.deep-merge(
    $secondary-set,
    map.set($secondary-sizes, $key, 'family', $secondary-font-family)
  );
}

$typography: map.merge($secondary-set, $primary-set);

@function getTypo($style, $prop: '') {
  @if map.has-key($typography, $style) {
    $fontSet: map.get($typography, $style);

    @if $prop != '' {
      @if map.has-key($fontSet, $prop) {
        @return map.get($fontSet, $prop);
      } @else {
        @error "Couldn't find properly '#{$prop}' in the type set '#{$fontSet}'. ####### SCSS ERROR #######";
      }
    }

    @return $fontSet;
  }

  @error "Couldn't find '#{$style}' in the type set. ####### SCSS ERROR #######";
}

@mixin getHeader($style) {
  @if ($style == 'h1') {
    font-family: getTypo('h800', 'family');
    font-size: getTypo('h800', 'size');
    font-weight: getTypo('h200', 'weight');
  }

  @if ($style == 'h2') {
    font-family: getTypo('h600', 'family');
    font-size: getTypo('h600', 'size');
    font-weight: getTypo('h200', 'weight');
  }

  @if ($style == 'h3') {
    font-family: getTypo('h400', 'family');
    font-size: getTypo('h400', 'size');
    font-weight: getTypo('h200', 'weight');
  }

  @if ($style == 'h4') {
    font-family: getTypo('h300', 'family');
    font-size: getTypo('h300', 'size');
    font-weight: getTypo('h200', 'weight');
  }

  @if ($style == 'h5') {
    font-family: getTypo('h200', 'family');
    font-size: getTypo('h200', 'size');
    font-weight: getTypo('h200', 'weight');
  }

  @if ($style == 'h6') {
    font-family: getTypo('h100', 'family');
    font-size: getTypo('h100', 'size');
    font-weight: getTypo('h100', 'weight');
  }
}
