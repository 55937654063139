@use '@scss/index.scss' as config;
@use '../button/styles.module.scss' as button;

.react-select-container {
  display: inline-block;
}

.react-select-control {
  cursor: pointer;
  @include button.baseButtonStyles();
  @include button.tertiaryStyles();

  &.button-size-small {
    @include button.smallButtonStyles();
    min-height: 30px;
    cursor: pointer;
  }

  &.button-type-secondary {
    @include button.secondaryStyles();
    cursor: pointer;
  }

  &.button-type-primary {
    @include button.primaryStyles();
    cursor: pointer;
  }
}

.react-select-container .react-select-menu {
  background-color: config.getColour('grey80');
  padding: config.getSpacer('xxs') config.getSpacer('xs');
  border-width: 2px;
  border-radius: 15px;
  border-color: config.getColour('grey60');
  border-style: solid;
  margin-top: config.getSpacer('xxs');
  width: 160px;
  right: 0;
  z-index: 10;
}

.react-select-menu .react-select-option {
  padding-top: config.getSpacer('xxs');
  padding-bottom: config.getSpacer('xxs');
  color: white;
  margin-right: config.getSpacer('md');
  width: 110px;
  position: relative;
  text-align: left;
  cursor: pointer;

  & > span > svg {
    position: absolute;
    right: 0;
    height: 10px;
    width: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  & > span > svg path {
    fill: config.getColour('primary50');
  }
}

.react-select-menu-list {
  overflow-x: hidden;
  z-index: 11;
}
