@use './index.scss' as config;
@import 'the-new-css-reset/css/reset.css';

body {
  background-color: config.getColour('grey100');
  color: config.getColour('grey0');
  letter-spacing: 1px;
  font-family: config.getTypo('h300', 'family');
  margin: 0;

  &.modal-active {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h5 {
  margin-block-start: 0;
  margin-block-end: 0;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: config.getTypo('bodySmall', 'weight');
  font-size: config.getTypo('bodySmall', 'size');
  line-height: config.getTypo('bodySmall', 'line-height');
}

#__next {
  min-width: 375px;
  display: flex;

  & > [tabindex='-1'] {
    width: 100%;
    display: flex;
  }
}
