@use 'sass:map';
@use '@scss/config/colours.scss' as colours;
@use '@scss/config/breakpoints.scss' as brkpnt;

$bRadius: 8px;

$borders: (
  small: (
    2px solid colours.getColour('grey80')
  ),
  large: (
    4px solid colours.getColour('grey80')
  )
);

@mixin responsiveBorder($withRadius) {
  @if $withRadius is defined {
    border-radius: $bRadius;
  }

  border: map.get($borders, small);

  @include brkpnt.breakpoint('md') {
    border: map.get($borders, large);
  }
}

@function getBorder($size) {
  @if map.has-key($borders, $size) {
    @return map.get($borders, $size);
  } @else {
    @debug "Couldn't find #{$size} in the border set.  ####### SCSS ERROR #######";
  }
}

@function getBorderRadius() {
  @return $bRadius;
}

@mixin borderHighlight () {
  border-radius: $bRadius;
  border: 2px solid rgba(255, 255, 255, 0.3);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}