@use 'sass:string';
@use 'sass:map';

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);

@mixin breakpoint($px, $type: 'up') {
  $updown: 'min-width';
  $value: 0;

  @if $type == 'down' {
    $updown: 'max-width';
  }

  @if string.index($px, 'px') {
    $value: $px;
  }

  @else {
    @if map.has-key($breakpoints, $px) {
      $value: map.get($breakpoints, $px);
    }

    @else {
      @debug "Couldn't find #{$px} in the breakpoint set.  ####### SCSS ERROR #######";
    }
  }

  @if $type != 'range' {
    @media only screen and (#{$updown}: $value) {
      @content;
    }
  }

  @if $type == 'range' {
    $lowValue: 0;
    $toggle: false;
    @each $name, $bpvalue in $breakpoints {
      @if $name != $px and $toggle == false {
        $lowValue: $bpvalue;
      }
      @else {
        $toggle: true;
        @debug $toggle;
      }
    }
    @debug 'only screen and (min-width: #{$lowValue}) and (max-width: #{$value})';
    @media only screen and (min-width: $lowValue + 1) and (max-width: $value) {
      @content;
    }
  }
}

@function getWidth($size) {
  @if map.has-key($breakpoints, $size) {
    @return map.get($breakpoints, $size);
  }

  @else {
    @debug "Couldn't find #{$size} in the breakpoint set.  ####### SCSS ERROR #######";
  }
}

//   Example usage
//
//   @include config.breakpoint('lg') {
//     background-color: green;
//   }

:export {

  @each $key,
  $value in $breakpoints {
    #{unquote($key)}: $value;
  }
}