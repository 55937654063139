@use '@scss/index.scss' as config;

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(config.getColour('grey100'), 0.5);
    z-index: 999;
    width: 100%;

    & > div {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        overflow: scroll;
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    &.solid-background {
        background-color: config.getColour('grey100');
    }
}

.offset-for-sidebar {
    left: config.$sidebar-xs;
    width: calc(100% -  config.$sidebar-xs);

    @include config.breakpoint('sm') {
        left: config.$sidebar-sm;
        width: calc(100% -  config.$sidebar-xs);
    }

    @include config.breakpoint('md') {
        left: config.$sidebar-sm;
        width: calc(100% -  config.$sidebar-sm);
    }

    @include config.breakpoint('xl') {
        left: config.$sidebar-lg;
        width: calc(100% -  config.$sidebar-lg);
    }
}

.modal-container {
    background-color: config.getColour('grey80');
    padding: config.getSpacer('lg');
    justify-self: center;
    align-self: center;
    margin: auto;
    min-width: 370px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include config.borderHighlight();
}