@use '@scss/index.scss' as config;

:root {
  --swiper-theme-color: black;
}

.swiper {
  padding: 0;
  overflow: visible;
  max-width: 1640px;
}

.swiper-horizontal > .swiper-pagination {
  bottom: 10px;
}

.swiper-wrapper {
  overflow: visible;
  max-width: 1600px;
}

.swiper-pagination {
  @include config.breakpoint('md') {
    // @media all and (min-width: $mobile) {
    display: none;
  }
}

.swiper-container {
  height: auto;
}

.swiper-slide {
  transition: all;
  height: auto;
  align-self: stretch;
  position: relative;
}

.quote-slider {
  & .swiper-slide-prev {
    right: 10px;

    @include config.breakpoint('sm') {
      // @media all and (min-width: $mobile) {
      right: 40px;
    }
  }

  & .swiper-slide-next {
    left: 10px;

    @include config.breakpoint('sm') {
      // @media all and (min-width: $mobile) {
      left: 40px;
    }
  }
}

.swiper-slide-active {
  width: 100%;
}
